<template>
  <div class="bind_bank">
    <div class="crumbs">
      <span class="first_span" @click="goBack">认证信息 </span>
      <span>/ {{ crumbsTitle }}</span>
    </div>
    <div class="fill_infor" v-if="activeNum === 1">
      <div class="name">
        <p class="lable">姓名</p>
        <p class="user_name">{{ name }}</p>
      </div>
      <el-form :label-position="labelPosition" label-width="120px" :model="basicForm" :rules="basicRules" ref="basicRule">
        <el-form-item label="卡号" prop="card_number">
          <el-input class="basic-input" v-model.trim="basicForm.card_number" @blur="getBnakName" placeholder="请输入银行卡号"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank_name">
          <el-input class="basic-input" v-model.trim="basicForm.bank_name" placeholder="请输入银行名称"></el-input>
        </el-form-item>
        <el-form-item label="开户支行" prop="branch_bank">
          <el-input class="basic-input" v-model.trim="basicForm.branch_bank" placeholder="请输入支行名称"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="reserved_mobile" required>
          <el-input class="basic-input" v-model.trim="basicForm.reserved_mobile" placeholder="请输入银行预留手机号"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="verify_mobile" v-if="activeNum === 2">
      <p>验证已绑卡的银行预留手机号</p>
      <div class="mobile">
        <p class="lable">手机号码</p>
        <p class="num">{{ phNum(basicForm.reserved_mobile) }}</p>
      </div>
      <div class="input">
        <el-input class="pass-input yz-input" v-model="verification_code" maxlength="6" placeholder="请输入验证码">
          <el-button v-if="!isMobile" @click="getCode()" slot="append" style="color: #81d8d0; padding: 9px 20px">获取验证码</el-button>
          <el-button slot="append" v-else icon="">{{ time }}s后获取</el-button>
        </el-input>
        <div class="tips_msg" v-if="showMsg">验证码已发送，请注意查收</div>
      </div>
    </div>
    <div class="footer">
      <el-button class="first_btn" @click="firstBind"> {{ first_btn }}</el-button>
      <el-button class="confirm sec_btn" type="primary" @click="secBind">{{ sec_btn }}</el-button>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: {
    reservedMobile: '',
    is_bind_bank: false,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      var patt = /^[1][3-9][\d]{9}/;
      if (value === '') {
        return callback(new Error('请输入银行预留手机号'));
      } else if (patt.test(value)) {
        callback();
      } else {
        return callback(new Error('请输入正确的手机号格式'));
      }
    };
    return {
      showMsg: false,
      labelPosition: 'right',
      crumbsTitle: '绑定银行卡',
      isMobile: false,
      time: 120,
      verification_code: '',
      verification_key: '',
      activeNum: 1,
      name: '',
      bankcardInfo: {},
      basicForm: {
        bank_name: '',
        branch_bank: '',
        card_number: '',
        reserved_mobile: '',
      },
      basicRules: {
        bank_name: [{ required: true, message: '请输入银行名称', trigger: 'blur' }],
        branch_bank: [{ required: true, message: '请输入支行名称', trigger: 'blur' }],
        card_number: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
        reserved_mobile: [{ trigger: 'blur', validator: checkNum }],
      },
    };
  },
  created() {
    this.getRealNmaeAuth();
  },
  mounted() {
    if (this.is_bind_bank) {
      this.getBankcard();
    }
  },
  computed: {
    first_btn() {
      if (this.activeNum === 1) {
        return '返回';
      } else {
        return '上一步';
      }
    },
    sec_btn() {
      if (this.activeNum === 1) {
        return '下一步';
      } else {
        return '确定';
      }
    },
  },
  methods: {
    // 获取实名认证结果
    getRealNmaeAuth() {
      let url = `${this.$api.realNameAuth}?is_visible=1`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.name = res.data.data.name;
        }
      });
    },
    // 获取银行卡结果
    getBankcard() {
      let url = `${this.$api.bankcard}?is_visible=${this.is_visible_bank}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.bankcardInfo = res?.data?.data;
          this.basicForm.bank_name = this.bankcardInfo.bank_name;
          this.basicForm.branch_bank = this.bankcardInfo.branch_bank;
          this.basicForm.card_number = this.bankcardInfo.card_number;
          this.basicForm.reserved_mobile = this.bankcardInfo.reserved_mobile;
        }
      });
    },
    // 银行卡识别
    getBnakName() {
      if (this.basicForm.card_number) {
        let url = `${this.$api.distinguishBankcard}?card_no=${this.basicForm.card_number}`;
        this.$http.get(url, true).then((res) => {
          if (res.data.success) {
            this.basicForm.bank_name = res.data.data.bank_name;
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      }
    },
    phNum(num) {
      return num.substr(0, 3) + '****' + num.substr(7);
    },
    getCode: _.debounce(function () {
      let url = this.$api.verification;
      let data = {
        type: 'check_phone',
        mobile: this.basicForm.reserved_mobile,
      };
      this.$http.post(url, data, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.isMobile = true;
          this.showMsg = true;
          this.verification_key = res.data.data.key;
        }
      });
      this.setIntervalFun();
    }, 300),

    setIntervalFun() {
      this.timer = setInterval(() => {
        if (this.time === 0) {
          this.isMobile = false;
          this.showMsg = false;
          this.time = 120;
          this.verification_key = '';
          clearInterval(this.timer);
          return;
        }
        this.time--;
      }, 1000);
    },

    firstBind() {
      if (this.activeNum === 1) {
        this.$refs.basicRule.resetFields();
        this.$emit('back', false);
      } else {
        this.isMobile = false;
        this.showMsg = false;
        this.time = 120;
        this.verification_key = '';
        this.verification_code = '';
        this.activeNum--;
      }
    },
    secBind() {
      let formName = this.$refs.basicRule;
      if (this.activeNum === 1) {
        // this.activeNum++;
        formName.validate((valid) => {
          if (valid) {
            let data = {
              bank_name: this.basicForm.bank_name,
              card_number: this.basicForm.card_number,
              branch_bank: this.basicForm.branch_bank,
              reserved_mobile: this.basicForm.reserved_mobile,
              is_verification: '1',
            };
            let url = this.$api.replaceBankcard;
            this.$http.post(url, data, true).then((res) => {
              if (res.data.success) {
                this.activeNum++;
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error',
                });
              }
            });
          } else {
            return false;
          }
        });
      } else {
        if (this.verification_key && this.verification_code) {
          let data = {
            bank_name: this.basicForm.bank_name,
            card_number: this.basicForm.card_number,
            branch_bank: this.basicForm.branch_bank,
            reserved_mobile: this.basicForm.reserved_mobile,
            verification_key: this.verification_key,
            verification_code: this.verification_code,
          };
          let url = this.$api.replaceBankcard;
          console.log(url);
          this.$http.post(url, data, true).then((res) => {
            console.log(res);
            if (res.data.success) {
              this.$message({
                message: '操作成功',
                type: 'success',
              });
              setTimeout(() => {
                this.$emit('back', true);
              }, 1500);
            }
          });
        } else {
          this.$message({
            message: '请获取验证码并填写',
            type: 'warning',
          });
        }
      }
    },
    goBack() {
      this.$emit('back', false);
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
@import '~assets/less/auth/bind_bank.less';
</style>
