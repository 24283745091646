<template>
  <div class="profile">
    <!--  编辑按钮-->
    <div class="edit-icon" @click="changeEdit(true)" v-if="(!isEdit && activeName != 'second' && activeName != 'third' && isSupertube) || activeName === 'first'"></div>
    <!--    <img src="~assets/images/profile/edit.png" class="edit-icon" @click="changeEdit(true)" alt="">-->
    <!--  标签页-->
    <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav">
      <!--    基本信息开始   -->
      <el-tab-pane label="基本信息" name="first">
        <el-form :label-position="labelPosition" label-width="120px" :model="basicForm" :rules="basicRules" ref="basicRule">
          <el-form-item label="昵称" prop="nickname">
            <el-input class="basic-input" v-if="isEdit" v-model="basicForm.nickname"></el-input>
            <div class="basic-text" v-else>{{ lookForm.nickname }}</div>
          </el-form-item>

          <!--        <el-form-item label="头衔">-->
          <!--          <div class="basic-text">{{ lookForm.title }}</div>-->
          <!--        </el-form-item>-->

          <el-form-item label="头像" prop="avatar">
            <upload-file
              v-if="isEdit"
              :url="url"
              @closeDialog="closeDialog"
              @successUrl="imgUrl"
              :limit="1"
              :folder="'circles'"
              :default_value="new Array(basicForm.avatar ? basicForm.avatar : '')"
            ></upload-file>
            <div class="block" v-else>
              <el-avatar shape="square" :size="60" :src="lookForm.avatar"></el-avatar>
            </div>
          </el-form-item>

          <el-form-item label="性别">
            <div class="" v-if="isEdit">
              <el-radio v-model="basicForm.gender" :label="1">男</el-radio>

              <el-radio v-model="basicForm.gender" :label="2">女</el-radio>
            </div>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.gender === 1 ? '男' : lookForm.managed_circle && lookForm.gender === 2 ? '女' : '' }}</div>
          </el-form-item>

          <el-form-item label="手机">
            <div class="basic-text">{{ lookForm.mobile }}</div>
          </el-form-item>

          <el-form-item label="邮箱" prop="email">
            <el-input class="basic-input" v-if="isEdit" v-model="basicForm.email"></el-input>
            <div class="basic-text" v-else>{{ lookForm.email }}</div>
          </el-form-item>
        </el-form>
        <div class="form-operation" v-if="isEdit">
          <el-button class="form-operation-item" @click="changeEdit(false)" round>取消</el-button>
          <el-button class="form-operation-item" type="primary" round @click="updata">保存</el-button>
        </div>
      </el-tab-pane>
      <!--    基本信息结束   -->

      <!--    注册信息开始    -->
      <el-tab-pane label="注册信息" name="second" v-if="is_admin_middleground === 'true'">
        <el-form :label-position="labelPosition" label-width="140px" :model="registerForm" :rules="registerRules" ref="registerRule">
          <el-form-item label="企业名称" prop="enterprise_name">
            <el-input class="register-input" v-if="isEdit" v-model="registerForm.enterprise_name" maxlength="50" show-word-limit></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.info.enterprise_name }}</div>
          </el-form-item>

          <el-form-item label="统一社会信用代码" prop="trust_code">
            <el-input class="register-input" v-if="isEdit" v-model="registerForm.trust_code"></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.info.trust_code }}</div>
          </el-form-item>

          <el-form-item label="营业执照" prop="url">
            <upload-file v-if="isEdit" @successUrl="Url" :limit="1" :folder="'circles'" :default_value="new Array(registerForm.url ? registerForm.url : '')"></upload-file>
            <div class="block" v-else>
              <el-avatar shape="square" :size="60" :src="lookForm.managed_circle && lookForm.managed_circle.info.url"></el-avatar>
            </div>
          </el-form-item>

          <el-form-item label="企业地址">
            <china-area v-if="isEdit" @areaChange="areaSuccess" :code="[registerForm.province, registerForm.city, registerForm.area]"></china-area>
            <div class="basic-text" v-else>
              {{ lookForm.managed_circle && lookForm.managed_circle.info.province + lookForm.managed_circle.info.city + lookForm.managed_circle.info.area }}
            </div>
          </el-form-item>

          <el-form-item label="详细地址">
            <el-input class="register-input" v-if="isEdit" v-model="registerForm.address"></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.info.address }}</div>
          </el-form-item>

          <el-form-item label="联系人姓名" prop="user_name">
            <el-input class="basic-input" v-if="isEdit" v-model="registerForm.user_name" maxlength="20" show-word-limit></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.info.user_name }}</div>
          </el-form-item>

          <el-form-item label="性别">
            <div class="" v-if="isEdit">
              <el-radio v-model="registerForm.gender" :label="1">男</el-radio>
              <el-radio v-model="registerForm.gender" :label="2">女</el-radio>
            </div>
            <div class="basic-text" v-else>
              {{ lookForm.managed_circle && lookForm.managed_circle.info.gender === 1 ? '男' : lookForm.managed_circle && lookForm.managed_circle.info.gender === 2 ? '女' : '' }}
            </div>
          </el-form-item>

          <el-form-item label="联系人邮箱">
            <el-input class="basic-input" v-if="isEdit" v-model="registerForm.email"></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.info.email }}</div>
          </el-form-item>

          <el-form-item label="联系人手机号">
            <el-input class="basic-input" v-if="isEdit" v-model="registerForm.mobile"></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.info.mobile }}</div>
          </el-form-item>
        </el-form>
        <div class="form-operation" v-if="isEdit">
          <el-button class="form-operation-item" @click="changeEdit(false)" round>取消</el-button>
          <el-button class="form-operation-item" type="primary" round @click="updata">保存</el-button>
        </div>
      </el-tab-pane>
      <!--    注册信息开始    -->

      <!--认证信息开始--->
      <el-tab-pane label="认证信息" name="third">
        <div class="certi_info" v-if="showMsg">
          <div class="real_name box">
            <div class="top" :class="this.is_real_name ? 'have_eyes' : ''">
              <img src="~assets/images/auth/real_name.png" alt="" />
              <p>实名认证</p>
              <div class="eyes bg_eyes" v-if="!showInfo && this.is_real_name" @click="openEyes('name')"></div>
              <div class="eyes bg_openeyes" v-if="showInfo && this.is_real_name" @click="closeEyes('name')"></div>
            </div>
            <div class="content">
              <div class="info" v-if="this.is_real_name">
                <div class="item_msg">
                  <div class="label">姓名</div>
                  <div class="msg">{{ this.is_real_name ? realNmaeInfo.name : '-' }}</div>
                </div>
                <div class="item_msg">
                  <div class="label">生日</div>
                  <div class="msg">{{ this.is_real_name ? realNmaeInfo.birthday : '-' }}</div>
                </div>
                <div class="item_msg">
                  <div class="label">性别</div>
                  <div class="msg">{{ this.is_real_name ? realNmaeInfo.gender : '-' }}</div>
                </div>
                <div class="item_msg">
                  <div class="label">身份证号</div>
                  <div class="msg">{{ this.is_real_name ? realNmaeInfo.id_number : '-' }}</div>
                </div>
              </div>
              <p class="noInfo" v-else>暂无数据</p>
            </div>
          </div>
          <div class="professional box">
            <div class="top">
              <img src="~assets/images/auth/professional.png" alt="" />
              <p>职业认证</p>
            </div>
            <div class="content">
              <div class="info" v-if="this.is_approval === 1">
                <div class="item_msg">
                  <div class="label">
                    {{ approvalsType === '医生' || approvalsType === '护士' || approvalsType === '技师' || approvalsType === '药师' || approvalsType === '其他' ? '单位' : '学校' }}
                  </div>
                  <div class="msg">{{ this.is_approval === 1 ? company : '-' }}</div>
                </div>
                <div class="item_msg">
                  <div class="label">
                    {{ approvalsType === '医生' || approvalsType === '护士' || approvalsType === '技师' || approvalsType === '药师' || approvalsType === '其他' ? '单位级别' : '专业背景' }}
                  </div>
                  <div class="msg">{{ this.is_approval === 1 ? hospital_level : '-' }}</div>
                </div>
                <div class="item_msg" v-if="approvalsType === '医生' || approvalsType === '护士' || approvalsType === '技师' || approvalsType === '药师' || approvalsType === '其他'">
                  <div class="label">科室</div>
                  <div class="msg">{{ this.is_approval === 1 ? department : '-' }}</div>
                </div>
                <div class="item_msg" v-if="approvalsType === '医生'">
                  <div class="label">职务</div>
                  <div class="msg">{{ this.is_approval === 1 ? hospital_post : '-' }}</div>
                </div>
                <div class="item_msg" v-if="approvalsType === '医生' || approvalsType === '护士' || approvalsType === '技师' || approvalsType === '药师' || approvalsType === '其他'">
                  <div class="label">职称</div>
                  <div class="msg">{{ this.is_approval === 1 ? job_title : '-' }}</div>
                </div>
              </div>
              <p class="noInfo" v-else>暂无数据</p>
            </div>
          </div>
          <div class="bank box">
            <div class="top">
              <div class="left" :class="this.is_bind_bank ? 'left' : 'nobind_bank'">
                <img src="~assets/images/auth/bank.png" alt="" />
                <p>绑定银行卡</p>
                <div class="eyes bg_eyes" v-if="!showBankInfo && this.is_bind_bank" @click="openEyes('bank')"></div>
                <div class="eyes bg_openeyes" v-if="showBankInfo && this.is_bind_bank" @click="closeEyes('bank')"></div>
              </div>
              <div class="right_btn" @click="changeBind">{{ is_bind_bank ? '更换绑定' : '立即绑定' }}</div>
            </div>
            <div class="content">
              <div class="info" v-if="this.is_bind_bank">
                <div class="item_msg">
                  <div class="label">卡号</div>
                  <div class="msg">{{ this.is_bind_bank ? bankcardInfo.card_number : '-' }}</div>
                </div>
                <div class="item_msg">
                  <div class="label">开户银行</div>
                  <div class="msg">{{ this.is_bind_bank ? bankcardInfo.bank_name : '-' }}</div>
                </div>
                <div class="item_msg">
                  <div class="label">开户支行</div>
                  <div class="msg">{{ this.is_bind_bank ? bankcardInfo.branch_bank : '-' }}</div>
                </div>

                <div class="item_msg">
                  <div class="label">手机号</div>
                  <div class="msg">{{ this.is_bind_bank ? bankcardInfo.reserved_mobile : '-' }}</div>
                </div>
              </div>
              <p class="noInfo last_noinfo" v-else>暂无数据</p>
            </div>
          </div>
        </div>
        <!--银行卡绑定信息-->
        <BindBnak v-else @back="back" :is_bind_bank="is_bind_bank" :reservedMobile="bankcardInfo.reserved_mobile"></BindBnak>
      </el-tab-pane>
      <!--认证信息结束--->

      <!--    小组信息开始    -->
      <el-tab-pane :label="field + '信息'" name="fours">
        <el-form :label-position="labelPosition" label-width="120px" :model="circleForm" :rules="circleRules" ref="circleRule">
          <el-form-item :label="field + '名称'" prop="name">
            <el-input class="basic-input" v-if="isEdit" v-model="circleForm.name"></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.name }}</div>
          </el-form-item>
          <el-form-item label="头像" prop="icon">
            <upload-file v-if="isEdit" :url="url" @successUrl="avatar" :limit="1" :folder="'circles'" :default_value="new Array(circleForm.icon ? circleForm.icon : '')"></upload-file>
            <div class="block" v-else>
              <el-avatar shape="square" :size="60" :src="lookForm.managed_circle && lookForm.managed_circle && lookForm.managed_circle.icon"></el-avatar>
            </div>
          </el-form-item>

          <el-form-item label="简介" prop="desc">
            <el-input class="register-input" v-if="isEdit" v-model="circleForm.desc"></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.desc }}</div>
          </el-form-item>

          <el-form-item label="公告">
            <el-input class="" style="width: 440px" v-if="isEdit" type="textarea" :rows="3" v-model="circleForm.announcement"></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.announcement }}</div>
          </el-form-item>
          <el-form-item label="频道类型" class="is-required">
            <!-- <el-radio-group v-model="circleForm.is_public" v-if="isEdit">
              <el-radio :label="false">否</el-radio>
              <el-radio :label="true">是</el-radio>
            </el-radio-group> -->
            <el-select v-if="isEdit" v-model="circleForm.type" style="width: 90px">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <div v-else class="basic-text">
              <span>{{ type }}</span>
            </div>
          </el-form-item>
          <el-form-item v-if="circleForm.type !== 'public'" label="分享次数" prop="share_count">
            <el-input class="" style="width: 440px" v-if="isEdit" type="input" v-model="circleForm.share_count"></el-input>
            <div class="basic-text" v-else>{{ lookForm.managed_circle && lookForm.managed_circle.share_count }}</div>
          </el-form-item>
          <el-form-item class="is-required" v-if="circleForm.type !== 'public'" label="有效时间">
            <el-input
              class="time_input"
              style="width: 140px; margin-right: 10px"
              @blur="leaveInput"
              v-if="isEdit"
              v-model.trim="circleForm.invitation_code_time"
              placeholder="请输入时长"
            ></el-input>
            <el-select v-if="isEdit" v-model="circleForm.unit" placeholder="单位" style="width: 90px">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <div class="basic-text" v-else>
              {{ lookForm.managed_circle && lookForm.managed_circle.invitation_code_time }} <span>{{ unit }}</span>
            </div>
          </el-form-item>
          <el-form-item class="btn" v-if="!isEdit && showBtn" label="H5链接">
            <el-button @click="copy" type="primary" class="link-btn par">复制链接</el-button>
          </el-form-item>
        </el-form>
        <div class="form-operation" v-if="isEdit">
          <el-button class="form-operation-item" @click="changeEdit(false)" round>取消</el-button>
          <el-button class="form-operation-item" type="primary" round @click="updata">保存</el-button>
        </div>
      </el-tab-pane>
      <!--    小组信息结束    -->
    </el-tabs>
  </div>
</template>

<script>
import uploadFile from 'components/uploadFile';
import ChinaArea from 'components/ChinaArea';
import _ from 'lodash';
import store from '@/store/store';
import BindBnak from './components/bindBnak.vue';
export default {
  name: 'Index',
  components: { uploadFile, ChinaArea, BindBnak },
  data() {
    var validatePass = (rule, value, callback) => {
      let checkNum = /^[0-9]\d*$/;
      if (!checkNum.test(Number(value))) {
        callback(new Error('分享次数只能是正整数'));
        // this.circleForm.share_count = '';
      } else {
        callback();
      }
    };
    return {
      showMsg: true,
      showBtn: false,
      showInfo: false,
      showBankInfo: false,
      field: '',
      url: 'image/browse',
      isEdit: false,
      imageUrl: '',
      activeName: 'first',
      labelPosition: 'right',
      approvalsType: '',
      //基本信息
      basicForm: {},
      //注册信息
      registerForm: {},

      //小组信息
      circleForm: {
        invitation_code_time: '0',
        // unit: 1,
        type: 'public',
      },
      typeList: [
        {
          value: 'public',
          label: '公开',
        },
        {
          value: 'private',
          label: '私有',
        },
        {
          value: 'benefit',
          label: '收益',
        },
      ],
      //实名信息
      realNmaeInfo: {},
      //职业信息
      approvalsInfo: [],
      //银行卡信息
      bankcardInfo: {},
      is_real_name: false,
      is_approval: 0,
      is_bind_bank: false,
      lookForm: {
        // time: 30,
      },
      unit: '',
      type: '',
      basicRules: {
        nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        // avatar: [
        //   { required: true, message: '请上传头像', trigger: 'blur' }
        // ],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
      },
      registerRules: {
        enterprise_name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        trust_code: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
        user_name: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        url: [{ required: true, message: '请上传营业执照', trigger: 'blur' }],
      },
      circleRules: {
        name: [{ required: true, message: '请输入小组名称', trigger: 'blur' }],
        icon: [{ required: true, message: '请上传头像', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入简介', trigger: 'blur' }],
        share_count: [
          { required: true, message: '请输入分享次数', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
      },
      options: [
        {
          value: 1,
          label: '周',
        },
        {
          value: 2,
          label: '日',
        },
        {
          value: 3,
          label: '时',
        },
        {
          value: 4,
          label: '分',
        },
        {
          value: 5,
          label: '秒',
        },
      ],
      is_admin_middleground: 'false', // 管理员
    };
  },
  created() {
    this.field = process.env.VUE_APP_FIELD;
    // if (this.userInfo && this.userInfo.id) {
    this.queryFun();
    this.getInfo();
    this.is_admin_middleground = localStorage.getItem('is_admin_middleground');
    // }
  },
  watch: {
    userInfo() {
      if (this.userInfo && this.userInfo.id) {
        this.queryFun();
        // this.getInfo();
      }
    },
  },
  methods: {
    openEyes(type) {
      if (type === 'name') {
        this.showInfo = true;
        this.getRealNmaeAuth();
      } else {
        this.showBankInfo = true;
        this.getBankcard();
      }
    },
    closeEyes(type) {
      if (type === 'name') {
        this.showInfo = false;
        this.getRealNmaeAuth();
      } else {
        this.showBankInfo = false;
        this.getBankcard();
      }
    },
    back(e) {
      this.showMsg = true;
      if (e) {
        this.getBankcard();
        this.getInfo();
      }
    },
    changeBind() {
      if (this.is_real_name && this.is_approval === 1) {
        this.showMsg = false;
      } else {
        this.$message({
          message: '请先完成实名认证和职业认证',
          type: 'warning',
        });
      }
    },
    // 获取实名认证结果
    getRealNmaeAuth() {
      let url = `${this.$api.realNameAuth}?is_visible=${this.is_visible_name}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.realNmaeInfo = res.data.data;
        }
      });
    },
    // 获取职业认证结果
    getApprovalsh() {
      let url = `${this.$api.approvals}?is_visible=1`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.approvalsInfo = res.data.data.model_info;
          this.approvalsType = res.data.data.name;
        }
      });
    },
    // 获取银行卡结果
    getBankcard() {
      let url = `${this.$api.bankcard}?is_visible=${this.is_visible_bank}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.bankcardInfo = res.data.data;
        }
      });
    },
    // 复制
    copy() {
      this.$http.get(this.$api.platformHost, false).then((res) => {
        if (res.data.success) {
          const input = document.createElement('input');
          document.body.appendChild(input);
          // input.setAttribute('value', `${res?.data?.data}/?uuid=${this.circleForm.uuid}&name=${this.circleForm.name}`);
          input.setAttribute('value', `${res?.data?.data}/articles?uuid=${this.circleForm.uuid}`);
          input.select();
          // if (document.execCommand('copy')) {
          document.execCommand('copy');
          this.$message.success('复制成功');
          // }
          document.body.removeChild(input);
        }
      });
    },
    // 关闭上传弹框
    closeDialog() {
      this.getInfo();
    },
    // 离开输入框
    leaveInput() {
      this.checkInput();
    },
    // 有效时间检查
    checkInput() {
      let checkNum = /^[0-9]\d*$/;
      if (this.circleForm.type !== 'public') {
        if (this.circleForm.invitation_code_time === '' || this.circleForm.invitation_code_time === undefined) {
          this.$message({
            message: '请输入时间',
            type: 'warning',
          });
          return false;
        } else {
          if (!checkNum.test(Number(this.circleForm.invitation_code_time))) {
            this.circleForm.invitation_code_time = '0';
            this.$message({
              message: '时间只能是正整数',
              type: 'warning',
            });
            return false;
          } else if (Number(this.circleForm.invitation_code_time) > 1000) {
            this.circleForm.invitation_code_time = '0';
            this.$message({
              message: '时间最大不能超过1000',
              type: 'warning',
            });
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({
        path: '/profile',
        query: {
          activeName: this.activeName,
        },
      });
    },
    // 参数赋值
    queryFun() {
      let query = this.$route.query;
      if (query) {
        if (query.activeName) {
          this.activeName = query.activeName;
        }
      }
    },
    //用户信息
    getInfo() {
      this.$http.get(this.$api.userInfo, true).then((result) => {
        if (result.data.success) {
          localStorage.setItem('is_bind_bank', result.data.data.is_bind_bank);
          localStorage.setItem('is_approval', result.data.data.is_approval);
          localStorage.setItem('is_admin_middleground', result.data.data.is_admin_middleground);
          localStorage.setItem('is_has_benefit_circle', result.data.data.is_has_benefit_circle);
          // localStorage.setItem('is_circle_author', result.data.data.is_circle_author);
          store.dispatch('A_USER_INFO', result.data.data);
          this.showBtn = true;
          this.basicForm = result.data.data;
          this.registerForm = result.data.data.managed_circle.info || {};
          this.circleForm = result.data.data.managed_circle;
          this.lookForm = result.data.data;
          this.is_real_name = result?.data?.data?.is_real_name;
          this.is_approval = result?.data?.data?.is_approval;
          this.is_bind_bank = result.data?.data?.is_bind_bank;
          if (this.is_real_name) {
            this.getRealNmaeAuth();
          }
          if (this.is_approval === 1) {
            this.getApprovalsh();
          }
          if (this.is_bind_bank) {
            this.getBankcard();
          }

          if (!this.lookForm.managed_circle.invitation_code_time) {
            this.lookForm.managed_circle.invitation_code_time = '0';
          }
          switch (this.lookForm.managed_circle.unit) {
            case 1:
              this.unit = '周';
              break;
            case 2:
              this.unit = '日';
              break;
            case 3:
              this.unit = '时';
              break;
            case 4:
              this.unit = '分';
              break;
            case 5:
              this.unit = '秒';
              break;
            case 0:
              this.circleForm.unit = 1;
              this.unit = '周';
              break;
          }
          switch (this.lookForm.managed_circle.type) {
            case 'public':
              this.type = '公开';
              break;
            case 'private':
              this.type = '私有';
              break;
            case 'benefit':
              this.type = '收益';
              break;
          }
        } else {
          this.showBtn = false;
        }
      });
      // this.basicForm = this.userInfo;
      // this.registerForm = this.userInfo.managed_circle.info;
      // this.circleForm = this.userInfo.managed_circle;
      // this.lookForm = JSON.parse(JSON.stringify(this.userInfo));
      /*let url = this.$api.userInfo
                this.$http.get(url, true).then(res => {
                  if (res.data.success) {
                    this.basicForm = res.data.data
                    this.registerForm = res.data.data.managed_circle.info
                    this.circleForm = res.data.data.managed_circle
                    this.lookForm = JSON.parse(JSON.stringify(res.data.data))
                  }
                })*/
    },
    //保存信息
    updata: _.debounce(function () {
      console.log(this.activeName);
      if (this.activeName === 'first') {
        this.$refs.basicRule.validate((valid) => {
          if (valid) {
            let url = this.$api.updateUser;
            let data = {
              nickname: this.basicForm.nickname,
              avatar: this.basicForm.avatar,
              gender: this.basicForm.gender,
              email: this.basicForm.email,
            };
            this.$http
              .post(url, data, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('保存成功');
                  this.isEdit = false;
                  this.getInfo();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          } else {
            return false;
          }
        });
      } else if (this.activeName === 'second') {
        this.$refs.registerRule.validate((valid) => {
          if (valid) {
            let url = this.$api.Circles + `/${this.basicForm.managed_circle.uuid}` + this.$api.infos;
            let data = {
              enterprise_name: this.registerForm.enterprise_name,
              trust_code: this.registerForm.trust_code,
              url: this.registerForm.url,
              address: this.registerForm.address,
              gender: this.registerForm.gender,
              user_name: this.registerForm.user_name,
              email: this.registerForm.email,
              mobile: this.registerForm.mobile,
              province: this.registerForm.province,
              city: this.registerForm.city,
              area: this.registerForm.area,
            };
            this.$http
              .put(url, data, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('保存成功');
                  this.isEdit = false;
                  this.getInfo();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          } else {
            return false;
          }
        });
      } else if (this.activeName === 'fours') {
        console.log(this.circleForm.unit);
        this.$refs.circleRule.validate((valid) => {
          if (valid && this.checkInput()) {
            // if (valid) {
            if (this.circleForm.type !== 'public') {
              if (this.circleForm.unit === '' || this.circleForm.unit === undefined) {
                this.$message({
                  message: '请选择单位',
                  type: 'warning',
                });
                return;
              }
            } else {
              this.circleForm.invitation_code_time = 0;
              this.circleForm.unit = 1;
            }

            let url = this.$api.Circles + `/${this.circleForm.uuid}`;
            var data = {
              name: this.circleForm.name,
              icon: this.circleForm.icon,
              desc: this.circleForm.desc,
              announcement: this.circleForm.announcement,
              share_count: this.circleForm.share_count,
              // is_public: this.circleForm.is_public,
              invitation_code_time: this.circleForm.invitation_code_time,
              unit: this.circleForm.unit,
              type: this.circleForm.type,
            };
            this.$http
              .put(url, data, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('保存成功');
                  this.isEdit = false;
                  this.getInfo();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          } else {
            return false;
          }
        });
      }
    }, 500),
    //上传图片
    imgUrl(data) {
      if (!data[data.length - 1]) {
        data[data.length - 1] = '';
      }
      this.basicForm.avatar = data[data.length - 1];
      this.$refs.basicRule.clearValidate('avatar');
    },
    //上传营业执照图片
    Url(data) {
      this.registerForm.url = data[data.length - 1];
      this.$refs.registerRule.clearValidate('url');
    },
    //小组log
    avatar(data) {
      this.circleForm.icon = data[data.length - 1];
      this.$refs.circleRule.clearValidate('icon');
    },
    //标签页切换
    handleClick() {
      this.isEdit = false;
      this.showMsg = true;
      this.linkFun();
    },
    //编辑
    changeEdit(val) {
      this.isEdit = val;
      this.$http.get(this.$api.userInfo, true).then((result) => {
        if (result.data.success) {
          console.log(result.data.data);
          this.basicForm = result.data.data;
          this.registerForm = result.data.data.managed_circle.info;
          this.circleForm = result.data.data.managed_circle;
          if (this.circleForm.unit === 0) {
            this.circleForm.unit = 1;
          }
        }
      });
      if (!val) {
        this.$refs['basicRule'].resetFields();
        this.$refs['registerRule'].resetFields();
        this.$refs['circleRule'].resetFields();
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 地址选择成功
    areaSuccess(data) {
      this.registerForm.province = data[0];
      this.registerForm.city = data[1];
      this.registerForm.area = data[2];
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    isSupertube() {
      return this.userInfo.is_supertube;
    },
    company() {
      return this.approvalsInfo[0]?.text;
    },
    hospital_level() {
      if (this.approvalsType !== '学生') {
        return this.approvalsInfo[1]?.select[0]?.name;
      } else {
        return this.approvalsInfo[1]?.text;
      }
    },
    department() {
      return this.approvalsInfo[2]?.text;
    },
    hospital_post() {
      return this.approvalsInfo[3]?.select[0]?.name;
    },
    job_title() {
      if (this.approvalsType !== '医生') {
        return this.approvalsInfo[3]?.select[0]?.name;
      } else {
        return this.approvalsInfo[4]?.select[0]?.name;
      }
    },
    is_visible_name() {
      if (this.showInfo) {
        return '1';
      } else {
        return '0';
      }
    },
    is_visible_bank() {
      if (this.showBankInfo) {
        return '1';
      } else {
        return '0';
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/profile/index.less';
</style>
<style>
.el-message {
  z-index: 999999 !important;
}
</style>
